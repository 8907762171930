<template>
  <div class="goods-rate">
    <div class="goods-section-title">
      <h3>评价</h3>
      <p class="count">({{ productInfo.commentCount || 0 }})</p>
    </div>
    <ul class="rate-list">
      <li v-for="rate in list.slice(0, 3)" :key="rate.id" class="rate-list-item">
        <div class="rate-user">
          <img :src="rate.headImg" />
          <span class="user">{{ rate.nickName }}{{ rate.isAnon ? '(匿名)' : '' }}</span>
          <span class="date">{{ rate.createTime }}</span>
        </div>
        <div class="rate-cnt van-multi-ellipsis--l3">
          {{ rate.content }}
        </div>
        <div v-if="rate.images && rate.images.length > 0" class="rate-attr">
          <span v-for="(img, index) in rate.images.split(';') || []" :key="img" class="img" @click="onClickRateImg(rate.images, index)"><img :src="img" /></span>
        </div>
      </li>
    </ul>
    <div v-if="list == 0" class="empty">
      暂无评价
    </div>
    <div v-if="hasMore" class="more" @click="onLookMore">
      查看全部评价
    </div>
  </div>
</template>

<script>
import Fetch from '@/common/fetch';
import { GetRateList } from '@/common/interface';
import { ImagePreview } from 'vant';

export default {
  name: 'GoodsRate',
  props: {
    productInfo: Object
  },
  data() {
    return {
      list: [],
      hasMore: false,
    }
  },
  created() {
    this.fetchRateList();
  },
  methods: {
    fetchRateList() {
      const params = {
        productId: this.productInfo.productId,
        productType: this.productInfo.productType,
        pageNum: 1,
        pageSize: 10,
      };
      Fetch.post(GetRateList, params).then((res) => {
        this.list = res.list || [];
        if (this.list.length > 3) {
          this.hasMore = true;
        }
      }).catch(() => {
      });
    },

    onClickRateImg(images, index) {
      ImagePreview({
        images: images.split(';') || [],
        startPosition: index,
      });
    },

    onLookMore() {

    },
  },
}
</script>

<style lang="scss">
.goods-rate {
  .rate-list {
    font-size: 12px;
    .rate-list-item {
      position: relative;
      padding: 6px 0 18px;
      .rate-user {
        line-height: 20px;
        img {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          vertical-align: middle;
        }
        .user {
          margin-left: 12px;
          display: inline-block;
          color: #262626;
          max-width: 8.2em;
          vertical-align: middle;
        }
        .date {
          float: right;
          color: #999;
        }
      }
      .rate-cnt {
        position: relative;
        line-height: 1.5;
        font-size: 13px;
        margin: 18px 0;
        word-break: break-all;
        overflow: hidden;
        white-space: normal;
        max-height: 126px;
      }
      .rate-attr {
        white-space: nowrap;
        overflow: hidden;
        overflow-x: auto;
        margin-right: -10px;
        .img {
          display: inline-block;
          width: 80px;
          height: 80px;
          margin-right: 6px;
          border-radius: 6px;
          overflow: hidden;
          text-align: center;
          vertical-align: middle;
          background-color: #f3f3f3;
          img {
            width: auto;
            display: inline-block;
            height: auto;
            min-width: 80px;
            max-height: 80px;
          }
        }
      }
    }
  }
  .empty {
    font-size: 13px;
    text-align: center;
    padding: 30px 0;
  }
  .more {
    text-align: center;
    padding: 8px 0;
    font-size: 12px;
  }
}
</style>